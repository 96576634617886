import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useServices } from '@/Services';
import { IS_DEV } from '@/utils/isDev';

import { IdbPartitionQueueRepository } from '../partition-queue/partition-queue.idb.repository';

import { InterviewManager, InterviewPartitionQueueRecord } from './interview-manager';
import { InterviewManagerStateType } from './interview-manager.types';

const InterviewManagerContext = createContext<InterviewManager | null>(null);

export function InterviewManagerProvider({ children }: PropsWithChildren<unknown>) {
  const { recordingSessionService, interviewSessionRepository } = useServices();
  const [searchParams] = useSearchParams();
  const debug = import.meta.env.DEV || searchParams.get('debug') === 'true';

  const interviewManager = useMemo(
    () =>
      new InterviewManager(
        recordingSessionService,
        interviewSessionRepository,
        new IdbPartitionQueueRepository<InterviewPartitionQueueRecord>(),
        IS_DEV ? 5 * 60 : 30 * 60,
        debug,
      ),
    [debug, interviewSessionRepository, recordingSessionService],
  );

  useEffect(() => {
    return () => {
      // This check will avoid issues when running in dev mode (useEffect running twice)
      if (interviewManager.getState().type !== InterviewManagerStateType.Empty) {
        interviewManager.destroy();
      }
    };
  }, [interviewManager]);

  return <InterviewManagerContext.Provider value={interviewManager}>{children}</InterviewManagerContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useInterviewManager(): InterviewManager {
  const interviewManager = useContext(InterviewManagerContext);
  if (!interviewManager) {
    throw new Error('useInterviewManager must be used inside InterviewManagerProvider');
  }
  return interviewManager;
}
